<template>
  <section class="tag pt-2">
    
    <div class="page-header">
      <h3 class="page-title">Tags</h3>
      <nav aria-label="breadcrumb">
        <router-link class="btn btn-primary float-right" :to="`/catalog/add-tag`">Add New</router-link>
      </nav>
    </div>
    
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tag, index) in tags" :key="tag.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ tag.name }}</td>
                  <td>
                    <label class="switch">
                      <input type="checkbox" @change="onToggleChange(tag.id, tag.active)" v-model="tag.active" />
                      <span class="slider round"></span>
                    </label>
                  </td>
                  <td>
                    <router-link :to="`/catalog/edit-tag/${tag.id}`" class="text-info">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </router-link>
                    <!-- <a v-on:click="deleteTag(tag.id)" class="text-primary">
                      <i class="mdi mdi-window-close font-size-18"></i>
                    </a> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!tags.length" class="col-12 p-3 text-center">No data found!</div>
            <paginate v-if="tags.length" :page-count="totalPage" :click-handler="clickCallback" 
                      :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Paginate from 'vuejs-paginate';
import { catalogApi } from "../../../api";

export default {
  name: "TagList",
  components: {  Paginate },
  data() {
    return {
      tags: [],
      
      totalPage: 1,
    };
  },
  mounted() {
    this.getTags(1);
  },
  methods: {
    async getTags(page) {
      
      const { data } = await catalogApi.getTags(page);
      
      this.tags = data.data.data.data;
      this.totalPage = data.data.data.last_page;
    },
    clickCallback(page) {
      this.getTags(page);
    },
    async onToggleChange(id, activeStatus) {
      const updatedStatus = activeStatus ? 1 : 0;
      
      const response = await catalogApi.updateTag(id, { active: updatedStatus });
      
      if (response.status === 200) {
        this.getTags(1);
        this.$swal({ toast: true, position: "top-end", showConfirmButton: false, text: response.messages, type: "success", timer: 3000 });
      } else {
        this.$swal({ type: "error", text: response.messages });
      }
    },
    async deleteTag(id, page) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.value) {
          
          const response = await catalogApi.deleteTag(id);
          
          if (response.data.code === 200) {
            this.getTags(page);
            this.$swal("Deleted!", "Your tag has been deleted.", "success");
          }
        }
      });
    }
  }
};
</script>
